import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function AlignCenterHorizontalIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M0 0v24h24V0H0zm19.938 5.68600781l-1.287 1.23400782c-.0905156.06937499-.1482891.17753906-.1482891.29920312 0 .02219531.0019219.04392188.0056016.06503906l-.0003281-.00225v9.06700779c-.0032578.0186094-.0051328.0400547-.0051328.0619219 0 .1215937.0577031.2296875.1472343.2984297l.0008907.0006562 1.257 1.2340078v.2710079h-6.3220079v-.27l1.302-1.2649922c.1279922-.1279922.1279922-.165.1279922-.36V8.99003906l-3.6199922 9.19500004h-.4900078L6.68995312 8.99003906v6.16300784c-.00492187.0343359-.00771093.0739922-.00771093.1143047 0 .2306015.09182812.4397343.24091406.592875l-.0001875-.0001875 1.69399219 2.0539921v.2710079H3.81496875v-.2699922l1.695-2.0550078c.14210156-.1472579.22966406-.3479766.22966406-.5691329 0-.0487031-.00424219-.0964218-.01239843-.1427812l.00072656.0049219V8.02703906c.0020625-.01903125.00323437-.04113281.00323437-.06346875 0-.18370312-.07938281-.34884375-.2056875-.46303125l-.00053906-.00049219-1.506-1.81399218v-.27h4.674l3.61300785 7.92300001 3.1759921-7.92400783H19.938v.27096094z" />
    </SvgIcon>
  );
}
